<template>
  <div class="bg-gray-800 text-white">
    <v-snackbar v-model="errorSnackbar" color="error" top>
      {{ errorMessage }}
      <v-btn text @click="errorSnackbar = false">Sluiten</v-btn>
    </v-snackbar>
    <div class="search">
      <v-text-field
        v-model="search"
        label="Opzoeken"
        prepend-icon="mdi-magnify"
        class="mx-4"
        clearable
        solo-inverted
        hide-details
        :outlined="false"
        :dense="true"
        :rounded="true"
        :placeholder="'Opzoeken ' + cards.length + ' Modules'"
      />
    </div>
    <v-container>
      <v-row>
        <v-col
          v-for="(card, index) in filteredCards"
          :key="index"
          cols="12"
          md="3"
        >
          <v-card max-width="250">
        <div style="display: flex;">
          <v-img src="@/assets/cronjob.png" height="100px" width="75px" contain></v-img>
        </div>
        
            <v-card-title>
              {{ card.naam }}
            </v-card-title>

            <v-card-subtitle>
              Dit is voor het wijzigen van de cronjobs statussen
            </v-card-subtitle>

            <v-card-actions>
              <v-btn
                block
                v-if="!card.status"
                style="background-color:#2196F3;color:white"
                @click="updateCard(card, true)"
              >
                Activeren
              </v-btn>
              <v-btn
                block
                v-else
                style="background-color:red;color:white"
                @click="updateCard(card, false)"
              >
                Deactiveren
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import apiCronjob from "../api/apiCronjob";
export default {
  data() {
    return {
      search: "",
      cards: [],
      errorSnackbar: false,
      errorMessage: "",
    };
  },
  async mounted() {
    this.loadModules();
  },

  computed: {
    filteredCards() {
      if (!Array.isArray(this.cards)) {
        return [];
      }
      return this.cards.filter((card) => {
        return card.naam.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },

  methods: {
    loadModules() {
      apiCronjob
        .haalCronjobsOp()
        .then((data) => {
          this.cards = data;
        })
        .catch((error) => {
          console.error("Failed to load modules:", error);
          // Behandel de fout afhankelijk van je vereisten
        });
    },
    async updateCard(card, status) {
      try {
        const payload = { status: status };
        await apiCronjob.updateCronjob(card.id, payload);
        this.loadModules();
        console.log("Module status updated");
      } catch (error) {
        console.error("Failed to update module status:", error);
        this.errorMessage = `Fout bij het updaten van de status: ${error.message}`;
        this.errorSnackbar = true;
      }
    },
  },
};
</script>

<style scoped>
.search {
  margin-bottom: 10px;
  margin-top: 40px;
}
</style>
