<template>
  <v-container style="padding-top: 4rem">
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="7">
            <div>
              <v-text-field
                label="Template naam"
                v-model="template.templateName"
                outlined
              ></v-text-field>

              <v-text-field
                label="Template Onderwerp"
                v-model="template.subject"
                outlined
              ></v-text-field>

              <v-text-field
                label="Naam van het bestand"
                v-model="template.attachment"
                outlined
              ></v-text-field>
            </div>
            <v-divider></v-divider>

            <vue-editor ref="editor1" v-model="template.template"></vue-editor>

            <div class="text-center" style="margin-top:3px">
              <v-btn
                style="background-color: #147D81; color: white; margin-right:10px"
                @click="updateTemplate()"
              >
                Template updaten
              </v-btn>
              <v-btn
                style="color:white; background-color:#cb403d"
                @click="goBack()"
              >
                Afsluiten
              </v-btn>
            </div>
          </v-col>

          <v-col cols="5">
            <div>
              <h5 style="text-align: center;">Beschikbare velden</h5>
              <v-row v-for="variable in template.variables" :key="variable.id">
                <v-col cols="12">
                  <v-btn
                    block
                    small
                    color="primary"
                    @click="insertVariable(variable.variableName)"
                  >
                    {{ variable.description }}
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import apiMailTemplate from "../api/apiMailTemplate";

import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor,
  },
  data: () => ({
    template: {},
  }),

  async mounted() {
    this.getTemplateData();
  },
  methods: {
    insertVariable(variableName) {
      const editor = this.$refs.editor1.quill;
      const range = editor.getSelection();
      if (range) {
        editor.insertText(range.index, `[${variableName}]`);
      }
    },
    goBack() {
      this.$router.push("/Mailtemplates");
    },
    getTemplateData() {
      apiMailTemplate
        .getMailTemplateById(this.$route.params.id)
        .then((data) => {
          this.template = data;
        });
    },
    async updateTemplate() {
      try {
        const id = this.$route.params.id;
        const response = await apiMailTemplate.updateMailTemplate(
          id,
          this.template
        );
        console.log(response);
        this.$swal.fire("Yes!", "Het bericht is gewijzigd!", "success");
        await this.getTemplateData();
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
  },
};
</script>

<style>
.space {
  margin: 3px;
}
.white-header {
  color: white !important;
  background-color: #147d81 !important;
}
</style>
