import { API } from "./api.js";
export default {
  async haalCronjobsOp() {
    try {
      const response = await API.get("/api/rest/v2/cronjobs");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateCronjob(id, status) {
    try {
      const response = await API.put(`/api/rest/v2/cronjobs/${id}`, status);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
